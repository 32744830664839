<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Views.Sync.Title") }}</h1>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>{{ $t("Copla.Common.Subtitles.Configuration") }}</h2>
          <div class="form-group row mt-4">
            <label
              for="title"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Name") }}</label
            >
            <div class="col-xl-10 mt-xl-1">
              <input
                type="text"
                class="form-control"
                name="title"
                id="title"
                min="1"
                v-model="title"
              />
            </div>
            <label
              for="server"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.SourceAudio") }}</label
            >
            <div class="col-xl-10 mt-xl-1">
              <select
                v-model="sourceAudio"
                class="custom-select"
                required="required"
              >
                <option
                  v-bind:value="value.id"
                  v-for="(value, id) in sourcesAudio"
                  :key="id"
                >
                  {{ datetimeString(value.date) }} - {{ value.name }} (
                  {{ value.type }})
                </option>
              </select>
            </div>
            <label
              for="server"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.SourceSubs") }}</label
            >
            <div class="col-xl-10 mt-xl-1">
              <select
                v-model="sourceSubs"
                class="custom-select"
                required="required"
              >
                <option
                  v-bind:value="value.id"
                  v-for="(value, id) in sourcesSubs"
                  :key="id"
                >
                  {{ datetimeString(value.date) }} - {{ value.name }} (
                  {{ value.type }})
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class=" col mr-3">
              <button
                type="button"
                id="comenzar"
                name="comenzar"
                class="btn btn-success"
                v-on:click="save"
              >
                {{ $t("Copla.Common.Buttons.Sync") }}
              </button>
            </div>
          </div>
        </div>
        <table class="table table-sm table-striped table-dark mt-5">
          <caption>
            {{
              $t("Copla.Views.Subtitles.Table.Updated")
            }}:
            {{
              updateTable
            }}
          </caption>
          <thead>
            <tr>
              <th>{{ $t("Copla.Views.Subtitles.Table.Date") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Name") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Type") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Language") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.SRT") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Transcription") }}</th>
              <th>
                <span class="reload" v-on:click="getCafs()">&#x21bb;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(caf, i) in sourcesSync" :key="i" :id="i">
              <td class="align-middle">{{ datetimeString(caf.date) }}</td>
              <td class="align-middle">{{ caf.name }}</td>
              <td class="align-middle">{{ caf.type }}</td>
              <td class="align-middle">{{ caf.language }}</td>
              <td>
                <button
                  v-if="caf.status == 'Succeeded'"
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getSRT(caf.id)"
                >
                  {{ $t("Copla.Common.Buttons.Download") }}
                </button>
              </td>
              <td>
                <button
                  v-if="caf.status == 'Succeeded'"
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getTranscription(caf.id)"
                >
                  {{ $t("Copla.Common.Buttons.Download") }}
                </button>
              </td>
              <td
                class="float-right reload align-middle"
                v-on:click="deleteCaf(caf.id)"
              >
                <i class="fas fa-trash"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
import MainMenu from "../components/MainMenu";
import formatSubtitlesAPI from "../js/formatSubtitlesAPI";
import auxiliarFunctions from "../js/AuxiliarFunctions";
import ModalLoading from "../components/ModalLoading.vue";
export default {
  name: "Sync",
  components: {
    MainMenu,
    ModalLoading
  },
  data() {
    return {
      mensajes: "",
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      sourceAudio: -1,
      sourceSubs: -1,
      sourcesAudio: [],
      sourcesSubs: [],
      sourcesSync: [],
      title: "Sync",
      fsAPI: new formatSubtitlesAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      showModalLoading: false,
      updateTable: 0,
      messageModal: ""
    };
  },
  methods: {
    showModal(status, message = "") {
      this.messageModal = message;
      this.showModalLoading = status;
    },
    getSRT(id) {
      this.showModal(true, this.$t("Copla.Common.Modal.Downloading"));
      this.fsAPI.getSRT(id, 0).then(response => {
        if (!response.error) {
          auxiliarFunctions.downloadFile(response.data.data, "subs.srt");
          this.showModal(false);
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    getTranscription(id) {
      this.showModal(true, this.$t("Copla.Common.Modal.Downloading"));
      this.fsAPI.getTranscription(id, 0).then(response => {
        if (!response.error) {
          auxiliarFunctions.downloadFile(
            response.data.data,
            "transcription.txt"
          );
          this.showModal(false);
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    getCafs: function() {
      this.sourcesAudio = [];
      this.sourcesSubs = [];
      this.sourcesSync = [];
      this.showModal(true, this.$t("Copla.Common.Modal.Loading"));
      let self = this;
      this.fsAPI.getCAFs().then(response => {
        console.log(response);
        if (!response.error) {
          this.showModal(false);
          response.data.data.forEach(function(value) {
            if (value.type == "File" || value.type == "Live") {
              if (value.status == "Succeeded") {
                self.sourcesAudio.push(value);
                if (self.sourcesAudio.length == 1) {
                  self.sourceAudio = value.id;
                }
              }
            } else if (value.type == "Sync") {
              self.sourcesSync.push(value);
              if (self.sourcesSync.length == 1) {
                self.sourceSync = value.id;
              }
            } else {
              self.sourcesSubs.push(value);
              if (self.sourcesSubs.length == 1) {
                self.sourceSubs = value.id;
              }
            }
          });
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    datetimeString: function(ts) {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      let date = new Date(ts * 1000);
      let hours = zeroPad(date.getHours(), 2);
      let minutes = zeroPad(date.getMinutes(), 2);
      let seconds = zeroPad(date.getSeconds(), 2);
      let day = zeroPad(date.getDate(), 2);
      let month = zeroPad(date.getMonth() + 1, 2);
      let year = date.getFullYear();
      return (
        day +
        "/" +
        month +
        "/" +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    save: function() {
      this.showModal(true, "Guardando");
      let self = this;
      let params = {
        _name: self.title,
        _audio: self.sourceAudio,
        _subs: self.sourceSubs
      };
      this.fsAPI.postSync(params).then(() => {
        self.showModal(false);
        self.getCafs();
      });
    }
  },
  mounted() {
    this.getCafs();
  }
};
</script>

<style scoped>
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
.btn-add-vocabulary {
  width: 100%;
}
.deleteGrammar {
  color: red;
}
.deleteGrammar:hover {
  cursor: pointer;
}
.vocabulario {
  border: 1px dotted black;
  padding: 10px;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
